
export const appConstants = {
    APP_USER_KEY : 'psc-user',
    APP_USER_SESSION_EXPIRED : 'avi-user-session-expired',
    APP_USER_SESSION_ERROR : 'avi-user-session-error',
    APP_USER_SESSION_UNAUTHORISED_ERROR_MESSAGE : 'You do not have privilege to access page!',
    APP_USER_SESSION_EXPIRED_MESSAGE : 'Your session has expired!',
    APP_USER_SESSION_AUTHENTICATION_REQUIRED_MESSAGE : 'You need to login to access page!',
    APP_ID : 'AIB Web',
    // APP_ID : 'Avicollect Web',
    INACTIVITY_EXPIRATION_LIMIT: 1200,
    //API URLS
    // PAS_URL: (process.env.NODE_ENV === 'development')? 'https://passerelle.test.vggdev.com' : window.env.passerelleUrl,
    PAS_URL: (process.env.NODE_ENV === 'development') ? 'https://passerelle.test.vggdev.com' : window.env.passerelleUrl,
    // NCAA_ORG_ID: (process.env.NODE_ENV === 'development') ? 2003 : window.env.ncaaOrgId,
    FAAN_ORG_ID: (process.env.NODE_ENV === 'development') ? 3024 : window.env.ncaaOrgId,
    FORGOT_PASSWORD_URL: (process.env.NODE_ENV === 'development') ? 'https://usermanager.test.vggdev.com/Account/ForgotPassword': window.env.forgotPasswordUrl,

    //Action Types
    ADD_LOG : 'add_log',
    OPEN_MODAL : 'open_modal',
    CLOSE_MODAL : 'close_modal',
    CLEAR_MODAL : 'clear_modal',
    //API Status
    API_REQUEST_START: 'API_REQUEST_START',
    API_REQUEST_FULL_PAGE_START: 'API_REQUEST_FULL_PAGE_START',
    API_REQUEST_FINISH: 'API_REQUEST_FINISH',
    REQUEST_SUCCESS: 'REQUEST_SUCCESS',
    REQUEST_FAILURE: 'REQUEST_FAILURE',
    GET_REQUEST : 'get',
    POST_REQUEST : 'post',

    NOTIFY_SUCCESS: 'success',
    NOTIFY_ERROR: 'error',
    NOTIFY_INFO: 'info',
    NOTIFY_INVALID: 'in',
    NOTIFY_WARNING: 'warning',
    NOTIFY_DANGER: 'danger',

    SUCCESS_RESPONSE: 1,
    ERROR_RESPONSE: 2,

    /*user Roles*/
    ROLES: {
        SUPER_ADMIN : "Super Admin",
        STAFF : "Staff",
        ADMIN : "Admin",
        BSP_ADMIN : "BSP",
        AIRLINE_ADMIN : "Airline_Admin",
        AIRLINE_STAFF : "Airline_Staff",
    },

    ROLES_PAGES: {
        SUPER_ADMIN: ['search', 'tsc'],
        ADMIN: ['timeline', 'upload', 'manifest', 'search', 'iata', 'tsc', 'reports', 'pdm-report', 'pdm-data-entry', 'unmatched-domestic-payment', 'billed-per-airport'],
        STAFF: ['search', 'reports', 'pdm-report', 'pdm-data-entry', 'billed-per-airport'],
        BSP_ADMIN: ['search', 'upload', 'manifest', 'flight', 'iata', 'invoice', 'tsc', 'proforma', 'payment-reconciliation', 'unmatched-payments', 'pdm-report', 'pdm-data-entry', 'match-payment', 'unmatched-domestic-payment', 'iata-validation', 'iata-validation-page', 'billed-per-airport'],
        AIRLINE_ADMIN: ['search', 'tsc'],
        AIRLINE_STAFF: ['search', 'tsc']
    },
    PAGES_INDEX: [
        {name : 'timeline', index: 0}, {name : 'search', index: 30}, {name : 'manifest', index: 30},
        {name : 'iata', index: 7}, {name : 'invoice', index: 8}, {name : 'tsc', index: 9}, {name : 'ftp-credentials', index: 10},
        {name : 'reports', index: 11}, {name : 'proforma', index: 12}, {name : 'payment-reconciliation', index: 13},
        {name : 'unmatched-payments', index: 13}, {name : 'unmatched-domestic-payment', index: 13}, {name : 'pdm-report', index: 50},
        {name : 'workflow', index: 77}, {name : 'iata-validation', index: 107}, {name : 'pdm-data-entry', index: 50},
        {name : 'billed-per-airport', index: 20},
    ],
    //actions
    ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
    USERS_SUCCESS: 'GET_USERS_SUCCESS',
    AIRLINES_SUCCESS: 'GET_AIRLINES_SUCCESS',
    AIRPORTS_SUCCESS: 'GET_AIRPORTS_SUCCESS',
    AIRLINE_PAYMENT_SUCCESS: 'GET_AIRLINE_PAYMENT_SUCCESS',
    AZMAN_PAYMENT_SUCCESS: 'GET_AZMAN_PAYMENT_SUCCESS',
    SIGN_IN_SUCCESS: 'POST_SIGN_IN_SUCCESS',
    SIGN_OUT: 'SIGN_OUT',
    ADD_DASHBOARD: 'ADD_DASHBOARD',

    /*Colors*/
    PRIMARY_COLOR: '#4caf50',
    DANGER_COLOR: '#f44336',
};
