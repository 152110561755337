import { appConstants } from '../_constants';
import { appHelpers, authHeader } from '../_helpers';

export const commonService = {
    getAllAirlines,
    getAllAirports,
    postSignIn,
    getUsersByOrganization,
    getOperationalData,
    getInternationalFinancialData,
    getDomesticFinancialData
};

function getOperationalData(payload) {
    return appHelpers.getRequest(`${appConstants.PAS_URL}/api/report/SplitOperationYear?airlineName=&startDate=${payload.startDate}&endDate=${payload.endDate}&pagesize=${500}`,  )
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        )
}

function getInternationalFinancialData(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/BillSummary/AIBSearch`, payload, authHeader())
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        )
}

function getDomesticFinancialData(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/Aggregate/AIBSearch`, payload, authHeader())
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        )
}

function getUsersByOrganization(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL }/api/User/Search`, payload, authHeader())
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

function postSignIn(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL }/api/Account/Login`, payload)
        .then(res => {
            const { error } = res.response;
            if(!error){
                return res;
            }else{
                return appHelpers.formatPromiseResponse(error, appConstants.ERROR_RESPONSE);
            }
        }).catch(
            error => {
                 let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

function getAllAirlines() {
    // return appHelpers.getRequest(`${appConstants.PAS_URL}/api/airline/search?IsLocal=${true}`, authHeader())
    return appHelpers.getRequest(`${appConstants.PAS_URL}/api/Airline/GetAllPscAirline`)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

function getAllAirports() {
    // return appHelpers.getRequest(`${appConstants.PAS_URL}/api/airline/search?IsLocal=${true}`, authHeader())
    return appHelpers.getRequest(`${appConstants.PAS_URL}/api/airport/search?isLocal=${true}`)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}
