import {appConstants} from '../_constants';
import {initialState} from "../_store";

export function airlines(state=initialState.airlines, action) {
    switch (action.type) {
        case appConstants.AIRLINES_SUCCESS:
            return action.airlines;
        default:
            return state
      }
}
