import React, { Component } from "react";
import {
  Button,
  Alert,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { alertActions, commonActions } from "../../../_actions";
import { connect } from "react-redux";
import { alertConstants, appConstants } from "../../../_constants";
import { store } from "../../../_store";
import { ProgressBar } from "../../../utils/ProgressBar";
import AIBLogo from "../../../assets/img/aiblogo.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentError: false,
      error: false,
      success: false,
      requesting: false,
      Username: "",
      Password: "",
    };
  }
  componentDidMount() {
    console.log("testing 1");
    const { dispatch } = this.props;
    const { alert, loginError } = store.getState();
    if (alert) {
      if (alert.type === alertConstants.SUCCESS) {
        this.setState({ error: false, success: alert.message });
      } else if (alert.type === alertConstants.CLEAR) {
        this.setState({ success: false, error: false });
      } else if (alert.type === alertConstants.ERROR) {
        this.setState({ success: false, error: alert.message });
      }
    }
    if (loginError.message) {
      this.setState({ success: false, error: loginError.message });
      dispatch(alertActions.clearLoginError());
    }
    dispatch(alertActions.resetApp());
  }

  componentWillReceiveProps = function (nextProps) {
    const { alert, loginError, dispatch } = nextProps;
    if (alert) {
      if (alert.type === alertConstants.SUCCESS) {
        this.setState({ error: false, success: alert.message });
      } else if (alert.type === alertConstants.CLEAR) {
        this.setState({ success: false, error: false });
      } else if (alert.type === alertConstants.ERROR) {
        this.setState({ success: false, error: alert.message });
        // dispatch(alertActions.clear());
        this.setState({ requesting: false });
      }
    }
    if (loginError.message) {
      this.setState({ success: false, error: loginError.message });
      dispatch(alertActions.clearLoginError());
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  validateInput = () => {
    const { Username, Password } = this.state;
    if (Username === "") {
      this.setState({ componentError: "Please enter username" });
      return false;
    } else if (Password === "") {
      this.setState({ componentError: "Please enter password" });
      return false;
    } else {
      this.setState({ componentError: false });
      return true;
    }
  };
  resetError = () => {
    this.setState({ error: false, componentError: false });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { Username, Password } = this.state;
    const { dispatch } = this.props;
    this.resetError();
    if (this.validateInput()) {
      this.setState({ requesting: true });
      let payload = JSON.stringify({ Username, Password });
      dispatch(commonActions.postSignIn(payload));
    }
  };
  renderPageResponse = () => {
    const { error, componentError, success } = this.state;
    if (error) {
      return <Alert color={"danger"}>{error}</Alert>;
    } else if (componentError) {
      return <Alert color={"danger"}>{componentError}</Alert>;
    } else if (success) {
      return <Alert color={"success"}>{success}</Alert>;
    }
  };

  render() {
    let pageResponse = this.renderPageResponse();
    const { Username, Password, componentError, error, requesting } =
      this.state;
    return (
      <div className="app flex-row align-items-center login-container">
        {requesting && <ProgressBar progress={requesting} />}
        <Container>
          <Row className="justify-content-center">
            <Col md="10">
              <CardGroup className={"login-section"}>
                <Card className="p-4 content">
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      {!error && !componentError && (
                        <div>
                          <h1>Login</h1>
                          <p className="text-muted">Sign In to your account</p>
                        </div>
                      )}

                      <div>{pageResponse}</div>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="Username"
                          value={Username}
                          onChange={this.handleChange}
                          type="text"
                          placeholder="Username"
                          autoComplete="username"
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="Password"
                          type="password"
                          value={Password}
                          onChange={this.handleChange}
                          placeholder="Password"
                          autoComplete="current-password"
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button
                            color="primary"
                            disabled={requesting}
                            className="px-4"
                          >
                            Login
                          </Button>
                        </Col>
                        <Col xs="6" className="text-right pt-2">
                          <a
                            href={appConstants.FORGOT_PASSWORD_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Forgot password ?
                          </a>
                          {/* <Button color="link" className="px-0">Forgot password?</Button> */}
                        </Col>
                      </Row>
                      <div
                        style={{
                          height: "2px",
                          marginTop: "10px",
                          borderTop: "solid 2px #000",
                        }}
                      >
                        &nbsp;
                      </div>

                      <Row style={{ marginTop: "10px", fontSize: "12px" }}>
                        <Col xs="6">
                          <p>
                            <i className="icon-phone"></i> 017000892
                          </p>
                        </Col>
                        <Col xs="6" className="text-right ">
                          <p>
                            <i className="icon-envelope"></i>{" "}
                            support@avitechng.com
                          </p>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <Card
                  className="text-white bg-primary py-5 d-md-down-none login-bg-container"
                  style={{ width: 44 + "%" }}
                >
                  <CardBody className={"content"}>
                    <div>
                      <h1 className="text-center">
                        <img src={AIBLogo} alt="" width={"100"} />
                      </h1>
                      <h4 className={"text-center"}>
                        Accident Investigation Bureau
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
function mapStateToProps(state, ownProps) {
  const { alert, user, requesting, loginError } = state;
  return {
    alert,
    user,
    requesting,
    loginError,
  };
}
export default connect(mapStateToProps)(Login);
