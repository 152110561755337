export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    LOGIN_ERROR: 'ALERT_LOGIN_ERROR',
    LOGIN_ERROR_CLEAR: 'LOGIN_ERROR_CLEAR',
    NOTIFY: 'ALERT_NOTIFY',
    CLEAR: 'ALERT_CLEAR',
    START_REQUEST: 'START_REQUEST',
    STOP_REQUEST: 'STOP_REQUEST',
    RESET_APP: 'RESET_APP',
};
