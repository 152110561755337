import { appConstants } from '../_constants';
import {initialState} from "../_store";

export function modal(state=initialState.modal, action) {
    switch (action.type) {
        case appConstants.OPEN_MODAL:
            return  Object.assign({}, state, {
                type: action.type,
                modalClass: action.payload.modalClass
            });
        case appConstants.CLOSE_MODAL:
            return  Object.assign({}, state, {
                type: action.type,
                modalClass: action.payload.modalClass
            });
        case appConstants.CLEAR_MODAL:
            return  Object.assign({}, state, {
                type: action.type
            });
        default:
          return state
      }
}
