import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Page404, Page500, Register, Landing } from './views/Pages';

//set localization for the app so that time and datepickers will have a reference for working
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import en from 'javascript-time-ago/locale/en'

// Time ago formatter.
import TimeAgo from 'javascript-time-ago'

// Tooltip CSS styles:
// import 'react-time-ago/Tooltip.css'

Moment.locale('en');
momentLocalizer();

// Add locale-specific relative date/time formatting rules.
TimeAgo.locale(en);

// import { renderRoutes } from 'react-router-config';

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/" name="Login Page" component={Landing} />
          <Route exact path="/landing" name="Login Page" component={Landing} />
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route path="/app" name="Home" component={DefaultLayout} />
          <Route path="**" name="Not Found" component={Page404} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
