export default {
  items: [
    {
      title: true,
      name: 'Menu',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
      {
          name: 'Financial',
          url: '/app/financial',
          icon: 'icon-calculator'
      },
      {
          name: 'Operational',
          url: '/app/operational',
          icon: 'icon-rocket'
      },
      {
          name: 'Flight Map',
          url: '/app/flight-map',
          icon: 'icon-location-pin'
      },
      {
          name: 'Business Intelligence',
          url: '/app/business',
          icon: 'icon-pie-chart'
      },
  ],
};
