export const initialState = {
    alert: {},
    modal: {},
    activity: {},
    api: false,
    user:  false,
    airlines: false,
    airports: false,
    airlinePayments: false,
    azmanPayment: false,
    users: false,
    progress: {isActive: false},
    requesting: false,
    dashboard: false,
    loginError: {},
};
