import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import AIBLogo from '../../../assets/img/aiblogo.png';
import Particles from 'react-particles-js';

const particlesConfig = {
    "particles": {
        "number": {
            "value": 30,
            "density": {
                "enable": true,
                "value_area": 800
            }
        },
        "color": {
            "value": "#fff"
        },
        "shape": {
            "type": "edge",
            "stroke": {
                "width": 0,
                "color": "#000000"
            },
            "polygon": {
                "nb_sides": 5
            },
            "image": {
                "src": "img/github.svg",
                "width": 100,
                "height": 100
            }
        },
        "opacity": {
            "value": 0.19728691040806817,
            "random": true,
            "anim": {
                "enable": false,
                "speed": 1,
                "opacity_min": 0.1,
                "sync": false
            }
        },
        "size": {
            "value": 10,
            "random": true,
            "anim": {
                "enable": false,
                "speed": 40,
                "size_min": 0.1,
                "sync": false
            }
        },
        "line_linked": {
            "enable": false,
            "distance": 500,
            "color": "#ffffff",
            "opacity": 0.4,
            "width": 2
        },
        "move": {
            "enable": true,
            "speed": 6,
            "direction": "bottom",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
                "enable": false,
                "rotateX": 600,
                "rotateY": 1200
            }
        }
    },
    "interactivity": {
        "detect_on": "canvas",
        "events": {
            "onhover": {
                "enable": true,
                "mode": "grab"
            },
            "onclick": {
                "enable": true,
                "mode": "repulse"
            },
            "resize": true
        },
        "modes": {
            "grab": {
                "distance": 400,
                "line_linked": {
                    "opacity": 0.5
                }
            },
            "bubble": {
                "distance": 400,
                "size": 4,
                "duration": 0.3,
                "opacity": 1,
                "speed": 3
            },
            "repulse": {
                "distance": 200,
                "duration": 0.4
            },
            "push": {
                "particles_nb": 4
            },
            "remove": {
                "particles_nb": 2
            }
        }
    },
    "retina_detect": false
}
const styles = {
 container: {
     position: 'relative',
     backgroundSize: 'cover',
     backgroundPosition: '50% 50%',
     overflow: 'hidden'
 },
  content: {
      position: 'absolute',
      top: 50,
      width: '100%',
      height: '100%',
      left: 0,
      right: 0
  },
    title: {
        color: '#fff'
    },
    subTitle: {
        color: '#b6a566'
    }
};

class Landing extends Component {
  render() {
    return (
      <div>
        <div style={styles.container} className={'landing-container'}>
            <Particles height={'100vh'} params={particlesConfig}/>
            <Container>
              <div style={styles.content}>
                <Row className="justify-content-center">
                    <Col md={12} lg={6}>
                        <div className="clearfix">
                            <h1 className="text-center">
                                <img src={AIBLogo} alt="" width={'200'}/>
                            </h1>
                        </div>
                    </Col>
                </Row>
                <Row className="dashboard-container">
                    <Col md={12} lg={6}>
                        <Link to={'/app/financial'}>
                            <div className={'dashboard-item'}>
                              <h1 className={'icons'}>
                                  <i className="icon-calculator icons font-9xl d-block mb-4"></i>
                              </h1>
                              <h4 className={'title'}>Financial</h4>
                              <p>
                                Financial data from operations.
                              </p>
                              <Button color="primary" className={'pr-5'}>Explore</Button>
                          </div>
                        </Link>
                  </Col>
                    <Col md={12} lg={6}>
                        <Link to={'/app/operational'}>
                            <div className={'dashboard-item'}>
                              <h1 className={'icons'}>
                                  <i className="icon-rocket icons font-9xl d-block mb-4"></i>
                              </h1>
                              <h4 className={'title'}>Operational</h4>
                              <p>
                                Passenger & Flight Statistics.
                              </p>
                              <Button color="primary" className={'pr-5'}>Explore</Button>

                          </div>
                        </Link>
                  </Col>

                    <Col md={12} lg={6}>
                        <Link to={'/app/operational'}>
                            <div className={'dashboard-item'}>
                                <h1 className={'icons'}>
                                    <i className="icon-location-pin icons font-9xl d-block mb-4"></i>
                                </h1>
                                <h4 className={'title'}>Flight Map</h4>
                                <p>
                                    Flight Tracking & Route Mapping.
                                </p>
                                <Button color="primary" className={'pr-5'}>Explore</Button>
                            </div>
                        </Link>
                    </Col>
                  <Col md={12} lg={6}>
                      <Link to={'/operational'}>
                          <div className={'dashboard-item'}>
                              <h1 className={'icons'}>
                                  <i className="icon-pie-chart icons font-9xl d-block mb-4"></i>
                              </h1>
                              <h4 className={'title'}>Business Intelligence</h4>
                              <p>
                                Data Analysis & Forecasting.
                              </p>
                              <Button color="primary" className={'pr-5'}>Explore</Button>

                          </div>
                      </Link>
                  </Col>

              </Row>
              </div>
            </Container>

        </div>

      </div>
    );
  }
}

export default Landing;
