import { combineReducers } from 'redux';

import { alert } from './alert.reducer';
import { activity } from './activity.reducer';
import { modal } from './modal.reducer';
import { api } from './api.reducer';
import { airlines } from './airline.reducer';
import { requesting } from './requesting.reducer';
import { user } from './user.reducer';
import { loginError } from './login.error.reducer';
import { users } from './users.reducer';
import {alertConstants} from "../_constants";


const appReducers = combineReducers({
    user,
    users,
    alert,
    modal,
    activity,
    api,
    airlines,
    requesting,
    loginError
});

const rootReducer = (state, action) => {
    if (action.type === alertConstants.RESET_APP) {
        state = undefined;
    }
    return appReducers(state, action);
};
export default rootReducer;
