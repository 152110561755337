import {appConstants} from '../_constants';
import {initialState} from "../_store";

export function api(state=initialState.api, action) {
    switch (action.type) {
        case appConstants.REQUEST_FAILURE:
            return Object.assign({}, state, action.message);
        default:
            return state
    }
}
