import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import { ProgressBar } from "../../utils/ProgressBar";
import {connect} from "react-redux";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {alertConstants} from "../../_constants";
import {alertActions} from "../../_actions";
import {store} from "../../_store";
import {appConstants} from "../../_constants";
import Page404 from "../../views/Pages/Page404";

class DefaultLayout extends Component {
  componentWillMount(){
      const { user } = store.getState();
      const { dispatch } = this.props;
      if(!user || !user.userId){
          dispatch(alertActions.loginError(appConstants.APP_USER_SESSION_AUTHENTICATION_REQUIRED_MESSAGE));
          window.location.href = '/#/login';
      }
  }
    constructor (props) {
        super(props);
        this.state = {

        };
    }

    componentWillReceiveProps = function (nextProps) {
        const { alert, dispatch } = nextProps;
        let msg_;
        if(alert){
            if(alert.type === alertConstants.NOTIFY ){
                msg_ = (typeof alert.message === 'string') ? alert.message : 'Unable to process request';
                this.createNotification('warning', msg_);
                dispatch(alertActions.clear());
            }else if(alert.type === alertConstants.SUCCESS ){
                msg_ = (typeof alert.message === 'string') ? alert.message : 'Request Successful';
                this.createNotification('success', msg_);
                dispatch(alertActions.clear());
            }else if(alert.type === alertConstants.ERROR ){
               dispatch(alertActions.clear());
                msg_ = (typeof alert.message === 'string') ? alert.message : 'Unable to process request';
                this.createNotification('error', msg_);
            }
        }
    };
        createNotification = (type, message) => {
                switch (type) {
                    case 'info':
                        NotificationManager.info(message);
                        break;
                    case 'success':
                        NotificationManager.success(message);
                        break;
                    case 'warning':
                        NotificationManager.warning(message);
                        break;
                    case 'error':
                        NotificationManager.error(message);
                        break;
                    default:
                        break;
                }

    };

  render() {
      const { requesting} = this.props;
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
            <ProgressBar progress={requesting}/>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navigation} {...this.props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <div style={{height: '15px'}}>

            </div>
              <NotificationContainer/>
            {/*<AppBreadcrumb appRoutes={routes}/>*/}
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                    return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                        <route.component {...props} />
                      )} />)
                      : (null);
                  },
                )}
                  <Route path="**" name="Not Found" component={Page404} />

                  {/*<Redirect from="/" to="/dashboard" />*/}
              </Switch>
            </Container>
          </main>
          <AppAside fixed>
            <DefaultAside />
          </AppAside>
        </div>
        <AppFooter>
          <DefaultFooter />
            {/*<ProgressBar progress={requesting}/>*/}
        </AppFooter>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    const { user, requesting, alert} = state;
    return {
        user,
        requesting,
        alert
    };
}
export default connect(mapStateToProps)(DefaultLayout);
