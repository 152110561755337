import { alertConstants } from '../_constants';
import {initialState} from "../_store";

export function loginError(state=initialState.loginError, action) {
    switch (action.type) {
        case alertConstants.LOGIN_ERROR:
            return Object.assign({}, state, {
                type: action.type,
                message: action.message
            });
        case alertConstants.LOGIN_ERROR_CLEAR:
            return {};
        default:
            return state
    }
}
